import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  db, 
  collection, 
  addDoc, 
  getDocs, 
  deleteDoc, 
  doc, 
  Timestamp 
} from '../Firebase';  // Import from our Firebase.jsx file

function Reminder() {
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [datetime, setDatetime] = useState('');
  const [frequency, setFrequency] = useState('once');
  const [reminders, setReminders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchReminders();
  }, []);

  const fetchReminders = async () => {
    const remindersCollection = collection(db, 'reminders');
    const remindersSnapshot = await getDocs(remindersCollection);
    const remindersList = remindersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setReminders(remindersList);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      // Save the reminder to Firestore
      await addDoc(collection(db, 'reminders'), {
        phoneNumber: phone,
        message: message,
        datetime: Timestamp.fromDate(new Date(datetime)),
        frequency: frequency,
        status: 'pending'
      });

      // Reset form
      setPhone('');
      setMessage('');
      setDatetime('');
      setFrequency('once');
      navigate('/thankyou');
    //   alert('Reminder set successfully!');
      fetchReminders();  // Refresh the reminders list
    } catch (error) {
      console.error('Error adding reminder:', error);
      alert('Failed to set reminder. Please try again.');
    }
  };

  const handleDeleteReminder = async (id) => {
    try {
      await deleteDoc(doc(db, 'reminders', id));
      alert('Reminder deleted successfully!');
      fetchReminders();  // Refresh the reminders list
    } catch (error) {
      console.error('Error deleting reminder:', error);
      alert('Failed to delete reminder. Please try again.');
    }
  };

  const formatDateTime = (timestamp) => {
    if (timestamp && typeof timestamp.toDate === 'function') {
      return timestamp.toDate().toLocaleString();
    } else if (timestamp && timestamp.seconds) {
      // If it's a Firestore Timestamp object but toDate() is not available
      return new Date(timestamp.seconds * 1000).toLocaleString();
    } else {
      return 'Invalid Date';
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-center">Reminder App</h1>
      
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-2xl font-bold mb-4">Set New Reminder</h2>
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Phone Number</label>
            <input
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Reminder Message</label>
            <textarea
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              value={message}
              onChange={e => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Date and Time</label>
            <input
              type="datetime-local"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              value={datetime}
              onChange={e => setDatetime(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Frequency</label>
            <select
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              value={frequency}
              onChange={e => setFrequency(e.target.value)}
            >
              <option value="once">Once</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
            </select>
          </div>
          <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Set Reminder
          </button>
        </form>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-bold mb-4">Your Reminders</h2>
        {reminders.length > 0 ? (
          <ul>
            {reminders.map((reminder) => (
              <li key={reminder.id} className="mb-4 p-4 border rounded-lg">
                <p><strong>Phone:</strong> {reminder.phoneNumber}</p>
                <p><strong>Message:</strong> {reminder.message}</p>
                <p><strong>Date:</strong> {formatDateTime(reminder.datetime)}</p>
                <p><strong>Frequency:</strong> {reminder.frequency}</p>
                <p><strong>Status:</strong> {reminder.status}</p>
                <button 
                  onClick={() => handleDeleteReminder(reminder.id)}
                  className="mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p>No reminders set yet.</p>
        )}
      </div>
    </div>
  );
}

export default Reminder;