import React from 'react';
import { Link } from 'react-router-dom';

const ThankYou = () => {
  const scrollToTops = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional smooth scrolling behavior
    });
  }
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-6 text-center">
        <h1 className="text-3xl font-bold text-gray-800">Thank You!</h1>
        <p className="text-lg text-gray-600 mt-4">
          Your message has been set.
        </p>
        <Link to="/"  onClick={scrollToTops} className="mt-6 inline-block bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600">
          GO to home page
        </Link>
      </div>
    </div>
  );
};

export default ThankYou;
