// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, Timestamp , collection, doc, addDoc, getDoc, getDocs, updateDoc, deleteDoc, query, where } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBO255BTpcuu7O3Vrl7Lam3OdOA0ptvHGQ",
  authDomain: "reapp-26abf.firebaseapp.com",
  projectId: "reapp-26abf",
  storageBucket: "reapp-26abf.appspot.com",
  messagingSenderId: "589681493550",
  appId: "1:589681493550:web:4463de85de62d5b19c81bf",
  measurementId: "G-DXSX5QGQ5Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const analytics = getAnalytics(app);
// export { db, analytics };
export {
  db,
  // auth,
  collection,
  doc,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  Timestamp,
  where
};


