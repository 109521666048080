import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ThankYou from './ThankYou';
import Reminder from './front/Reminder';
function App() {
  return (
    <Router>
      <Routes>
        <Route>
        <Route path="/" element={<Reminder />} /> 
        <Route path="/thankyou" element={<ThankYou />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;